<template>
  <ui-message
    @messageClose="messageClose"
    :message="message"
    :messageProp="messageProp"
  />
</template>

<script>
import { mapActions } from "vuex";
import UiMessage from "../components/ui/ui-message.vue";

export default {
  name: "Redirect",
  components: { UiMessage },
  created() {
    this.authHandler();
  },
  methods: {
    ...mapActions(["GET_TOKEN", "GET_TOKEN_REFRESH"]),

    authHandler() {
      this.axios
        .post("/api/login_check", {
          username: this.$route.query.username,
          password: this.$route.query.password
        })
        .then((response) => {
          this.GET_TOKEN(response.data.token);
          this.GET_TOKEN_REFRESH(response.data.refresh_token);
          this.$router.push("/");
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status === 401) {
            (this.message = true),
              (this.messageProp = {
                class: "alert__danger",
                title: "Неверные данные для входа",
                text: "Вернитесь назад"
              });
          }
        });
    },

    messageClose() {
      this.message = false;
      this.messageProp = {};
    }
  }
};
</script>
